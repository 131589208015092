import React from "react";
import { createRoot } from "react-dom/client";

import ReactGA from "react-ga4";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider } from "react-apollo";
import { DndProvider } from "react-dnd";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

import {
    ModalProvider,
    ModalRenderer,
    QoplaProvider,
    DrawerProvider,
    DrawerRender,
    AuthUserProvider
} from "Providers";
import { LanguageProvider } from "LanguageProvider";
import { ThemeProvider } from "ThemeProvider";
import { envConstants, frontyEnv } from "Constants";
import { GlobalStyles } from "Atoms";
import { apolloClient } from "./graphql/clients/ApolloConfig";
import RoutingProvider from "./providers/routingProvider/RoutingProvider";
import { FlatProviders } from "Components";
import "whatwg-fetch";
import "semantic-ui-css/semantic.min.css";
import "./styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSentryMemoryReport } from "Utils";
import "./sentryInit";
import GeneralErrorBoundary from "./SentryErrorBoundary";

//TODO: 1. update to typescript file

if (envConstants.PROFILE === frontyEnv.PROD) {
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
} else if (envConstants.PROFILE === frontyEnv.DEV) {
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
} else if (envConstants.PROFILE === frontyEnv.LOCAL) {
    // If you want to troubleshoot/see what events are firing, switch these two lines and open the dev console.
    // ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID, { debug: true });
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
}

const providers = [
    <HelmetProvider />,
    <DndProvider backend={HTML5Backend} />,
    <LanguageProvider />,
    <ThemeProvider />,
    <ApolloProvider client={apolloClient} />,
    <ModalProvider />,
    <DrawerProvider />,
    <AuthUserProvider />,
    <QoplaProvider />
];

const App = () => (
    <>
        <GlobalStyles />
        <GeneralErrorBoundary>
            <FlatProviders providers={providers}>
                <RoutingProvider />
                <ModalRenderer />
                <DrawerRender />
                <ToastContainer closeOnClick draggable />
            </FlatProviders>
        </GeneralErrorBoundary>
    </>
);


// Maybe not the most elegant place to put this, but it's the best place to put it for now.
useSentryMemoryReport();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
