import Axios from "axios";
import { useEffect } from "react";

import { useInterval } from "CoreHooks";
import { ReceiptPrinter } from "../admin/components/pos-admin/utils/posTypes";

type HeartBeatPrinterProps = {
    receiptPrinter: ReceiptPrinter;
    onHeartbeatChange: (connected: boolean, isNetworkError?: boolean) => void;
    pollInterval?: number;
};

export const useHeartbeatReceiptPrinter = ({
    receiptPrinter,
    onHeartbeatChange,
    pollInterval = 30000
}: HeartBeatPrinterProps) => {
    const heartBeatXml = `<?xml version="1.0" encoding="utf-8"?>
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        </epos-print>
        </s:Body>
        </s:Envelope>`;

    const pingReceiptPrinter = async () => {
        try {
            const { data } = await Axios.post(
                `https://${receiptPrinter.ip}/cgi-bin/epos/service.cgi?devid=${receiptPrinter.deviceName}&timeout=3000`,
                heartBeatXml,
                { timeout: 3000 }
            );
            onHeartbeatChange(data?.includes('success="true"') ?? false);
        } catch (error: any) {
            onHeartbeatChange(false, true);
        }
    };

    useEffect(() => {
        pingReceiptPrinter();
    }, []);

    useInterval(() => {
        pingReceiptPrinter();
    }, pollInterval);
};
